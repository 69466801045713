


















import { defineComponent, onMounted, ref } from "@nuxtjs/composition-api";
import { SfButton } from "@storefront-ui/vue";
import useApi from "~/composables/useApi";
import type { CategoryTree } from "~/modules/GraphQL/types";
import CategoryProductsGql from "~/pages/CategoryProducts.gql";

import { useProduct } from "~/composables";
import ProductsGrid from "~/modules/catalog/product/components/ProductsGrid.vue";
export default defineComponent({
	name: "HighlightProduct",
	components: {
		ProductsGrid,
		CategoryProducts: () =>
			import(/* webpackPrefetch: true */ "~/components/CategoryProducts.vue"),
		SfButton,
	},
	setup() {
		const { query } = useApi();
		const categoryData = ref([]);
		const items = ref();
		const topContent = ref();
		const urlKey = ref();
		const catName = ref();
		const loading = ref(true);

		const fetchHighlight = async () => {
			const { data } = await query<{ categoryList: [CategoryTree] }>(
				CategoryProductsGql,
				{
					filters: {
						url_key: { eq: "highlight-product" },
					},
					pageSize: 8,
				},
			);
			data.categoryList.map((value, index) => {
				items.value = value.products;
				topContent.value = value?.top_content;
				urlKey.value = value?.url_key;
				catName.value = value?.name;
			});
		};

		onMounted(async () => {
			await fetchHighlight();
			loading.value = false;
		});

		return {
			categoryData,
			topContent,
			items,
			loading,
			urlKey,
			catName,
		};
	},
});
